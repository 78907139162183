.Vision {

    h3 {
        color: $color_light-green;
    }

    h2 {
        // color: #AAB43A !important;
    }

    p {
        font-size: $font_size-medium;
    }

    a {
        text-decoration: underline;
        color: $color_brand;
    }

    .row {
        margin: $dims_padding-medium 0;

        @include respond-to(tablet) {
            display: flex;
            gap: $dims_padding-medium;
        }
    }

    .col {
        flex: 1 1 50%;

        + .col {
            margin-top: $dims_padding-medium;

            @include respond-to(tablet) {
                margin-top: 0
            }
        }
    }

    .image-wrap {
        text-align: center;
        border-radius: $box_radius-medium;
        overflow: hidden;

        img {
            @extend ._vertical-align;
            min-height: 100%;
            min-width: 100%;
            left: 0;
            right: 0;
            border-radius: $box_radius-medium;
            margin: auto;
        }
    }

    h4 {
        font-weight: 400;
    }

}
