@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';

.Resources, .Survey {

    h2 {
        color: #347884 !important;
    }

    ul {
        @include respond-to(tablet) {
            display: flex;
            flex-wrap: wrap;
            gap: $dims_padding-medium;
        }
    }

    .Henshu__EditableImage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    ul > li, ol.ugh > li {
        $size: 22rem;

        flex: 0 0 $size;
        height: $size * .9;
        max-width: 300px;
        width: 100%;
        margin: auto;
        margin-bottom: $dims_padding-small;

        text-align: center;

        background: $color_bg-light;
        border-radius: $box_radius-medium;
        box-shadow: layout.$box-shadow--large;
        cursor: pointer;
        overflow: hidden;

        @include respond-to(tablet) {
            margin: 0;
            height: $size;
            max-width: 100%;
        }
    }
}

.Resource {
    height: 100%;

    background-position: center center;
    background-size: cover;

    a {
        display: block;
        width: 100%;
        height: 100%;
        color: inherit;
    }

    &:hover, &:focus {
        .resource-content {
            svg, img {
                left: -50px;
                transition: left .2s;
            }
        }

        .resource-content:before {
            background: darken($color_bg-light, 5);
            transition: background .2s;
        }
    }

    .resource-content {
        position: absolute;
        bottom: 0;
        z-index: 10;

        width: 100%;
        height: 40%;
        padding: 1rem 3rem 3rem;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -10%;
            left: -5%;

            transform: rotate(-6deg);

            height: 200%;
            width: 110%;

            background: $color_bg-light;
            transition: background .2s;
        }
    }

    .icon-wrapper {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;

        height: 50px;
        width: 50px;

        text-align: center;
        margin: auto;

        background: $color_bg-light;
        border-radius: 50%;
        box-shadow: $box_shadow;
        overflow: hidden;

        svg {
            height: 60%;
            color: $color_brand;
        }

        svg, img {
            display: inline-block;
            width: 60%;
            top: 20%;
            left: 0;

            transition: left .2s;
        }

        img {
            position: absolute;
            transform: translateX(60px);
        }
    }

    .resource-meta {
        z-index: 100;
        padding: layout.$padding;

        background: color.$white;
        display: flex;
        align-items: left;
    }

    h4 {
        margin-top: $dims_padding-small;
        margin-bottom: 0;

        font-size: $font_size-medium;
        font-weight: 600;
    }

    p {
        margin-top: $dims_padding-small;
    }
}

